import { Button, Form, Input } from "components/form";

import styles from "./mdtcform.module.css";
import { ChangeEvent, useState } from "react";
import { useMutation, useQuery } from "react-query";
import client from "helpers/apiClient";
import toast from "components/toast";

type MTDCDataType = {
  subawardExclusion: number;
  deMinimisRate: number;
};

export function MTDCForm({ grantId }: { grantId: string }) {
  const { data } = useQuery({
    queryKey: ["mtdc", grantId],
    queryFn: async () => {
      const response = await client.get(`/grants/${grantId}/mtdc/subawards`);
      return response.data as MTDCDataType;
    },
  });

  if (!data) {
    return null;
  }

  return <InternalForm grantId={grantId} defaultValue={data} />;
}

export function InternalForm({
  grantId,
  defaultValue,
}: {
  grantId: string;
  defaultValue: MTDCDataType;
}) {
  const [data, setData] = useState(defaultValue);
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const updateSubrewards = useMutation({
    mutationFn: async () => {
      await client.post(`/grants/${grantId}/mtdc/subawards`, {
        deMinimisRate: data.deMinimisRate,
        subawardExclusion: data.subawardExclusion,
      });
    },
    onSuccess: () => {
      toast.info("Updated Subaward Exclusion and de minimis rate");
    },
  });
  function handleSubmit() {
    let hasError = false;
    if (!data.deMinimisRate) {
      setErrors((e) => ({ ...e, deMinimisRate: ["Field is required"] }));
      hasError = true;
    } else {
      setErrors((e) => ({ ...e, deMinimisRate: [] }));
    }
    if (!data.subawardExclusion) {
      setErrors((e) => ({ ...e, subawardExclusion: ["Field is required"] }));
      hasError = true;
    } else {
      setErrors((e) => ({ ...e, subawardExclusion: [] }));
    }

    if (hasError) {
      return;
    }
    setErrors({});
    console.log("mutating");
    updateSubrewards.mutateAsync();
  }

  return (
    <Form prompt onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.field}>
        <Input
          name="subawardExclusion"
          className={styles.fullInput}
          value={data.subawardExclusion}
          placeholder="Enter Subaward Exclusion"
          label="Subaward Exclusion"
          errors={errors.subawardExclusion}
          money
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setData((d) => ({ ...d, subawardExclusion: +event.target.value }));
          }}
        />
      </div>
      <div className={styles.field}>
        <Input
          name="deMinimisRate"
          className={styles.fullInput}
          value={data.deMinimisRate}
          placeholder="Enter de minimis rate"
          label="De Minimus Rate"
          type="number"
          errors={errors.deMinimisRate}
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setData((d) => ({ ...d, deMinimisRate: +event.target.value }));
          }}
        />
      </div>
      <div className={styles.yearsFooter}>
        <Button
          htmlType="button"
          size="lg"
          name="submit"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </Form>
  );
}
