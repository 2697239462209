import { get, isString, isNaN, isObject } from "lodash";
import { FieldError, FieldErrors } from "react-hook-form";
type Field = {
  key: string;
  label: string;
  error?: string;
};

export type Errors = Record<string, string[]>;

const parseErrors = (
  errors?: Record<string, string>,
  prefix?: string
): Errors => {
  if (!errors) return {};
  return Object.keys(errors).reduce((result: Errors, key: string) => {
    const value = errors[key];
    const label = prefix ? `${prefix}.${key}` : key;
    if (isString(value)) {
      return {
        ...result,
        [label]: [value],
      };
    }
    if (isObject(value))
      return {
        ...result,
        ...parseErrors(value as Record<string, string>, label),
      };
    return result;
  }, {});
};

export const localEmptyValidate = (
  fields: Field[],
  data: Record<string, any>,
  message?: string
): Errors => {
  const defaultMessage = "Please enter your";
  return fields.reduce((result: Errors, field: Field) => {
    const text = field.error || `${message || defaultMessage} ${field.label}`;
    const value = get(data, field.key);
    if (value === undefined || value === null || isNaN(value) || value === "")
      return { ...result, [field.key]: [text] };
    return result;
  }, {});
};

export const localRequiredValidate = (
  fields: Field[],
  data: Record<string, any>
): Errors => {
  return fields.reduce((result: Errors, field: Field) => {
    const text = field.error || `Required field`;
    const value = get(data, field.key);
    if (value === undefined || value === null || isNaN(value) || value === "")
      return { ...result, [field.key]: [text] };
    return result;
  }, {});
};

export type RHErrors = Record<string, RHError>;
type RHError = {
  message: string;
  ref: any;
  type: string;
};

export const findInputError = (errors: RHErrors, name: string): string[] => {
  const error = get(errors, name);
  if (error) return [error.message];
  return [];
};

export default parseErrors;
