import {
  FC,
  useCallback,
  MouseEvent,
  useContext,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import Typo from "components/typo";
import Icon from "components/icon";
import useContextMenu from "context/useContextMenu";
import { StatGrant } from "store/grants/types";
import useGrants from "hooks/useGrants";
import useUrl from "hooks/useUrl";
import Folder, { FolderProps } from "pages/grant/paper/card/folder";
import Personnel from "pages/grant/paper/card/folders/personnel";
import Travel from "pages/grant/paper/card/folders/travel";
import ParticipantSupport from "pages/grant/paper/card/folders/participant";
import OtherDirectCosts from "pages/grant/paper/card/folders/otherDirectCosts";
import Fees from "pages/grant/paper/card/folders/fees";
import CostSharing from "pages/grant/paper/card/folders/costSharing";
import styles from "./card.module.css";
import Equipment from "./folders/equipment";
import Facilities from "./folders/facilities";
import MTDC from "./folders/mtdc";
import Indirect from "./folders/indirect";
import Anchor from "./anchor";
import Tip from "components/tip";
import { useGrantSubscription } from "hooks/useGrantSubscription";

type Props = {
  item: StatGrant;
  last?: boolean;
  search?: string;
};

const Card: FC<Props> = ({ item, last, search = "" }) => {
  const { getSide, getSideFromStore, getSidebarTotalsFromStore } = useGrants();
  const paid = useGrantSubscription(item.id);
  const side = getSideFromStore(item.id);
  const sidebarTotals = getSidebarTotalsFromStore(item.id);
  const url = useUrl();
  const [opened, onChangeOpened] = useState<boolean>(url.grantId === item.id);
  const { onShowMenu } = useContext(useContextMenu);

  const getHighlightedText = useCallback(
    (text: string) => {
      const searchRegexp = new RegExp(search, "i");
      return search
        ? text.replace(
            searchRegexp,
            (value: string) =>
              `<span style="background-color: #30D5C8" >${value}</span>`
          )
        : `<span>${text}</span>`;
    },
    [search]
  );

  const folders: FolderProps[] = useMemo(
    () => [
      {
        item: {
          label: "👨‍🚀 Personnel",
          type: "personnel",
          paid: false,
        },
        children: side ? (
          <Personnel totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
      {
        item: {
          label: "🔬 Equipment",
          type: "equipment",
          paid: !paid,
        },
        children: side ? (
          <Equipment totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
      {
        item: {
          label: "🛬 Travel",
          type: "travel",
          paid: !paid,
        },
        children: side ? (
          <Travel totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
      {
        item: {
          label: "🙋 Participant Support",
          type: "events",
          paid: !paid,
        },
        children: side ? (
          <ParticipantSupport totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
      {
        item: {
          label: "🧪 Other Direct Costs",
          type: "otherDC",
          paid: !paid,
        },
        children: side ? (
          <OtherDirectCosts totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
      {
        item: {
          label: "🧮 MTDC",
          type: "mtdc",
          paid: !paid,
        },
        children: side ? <MTDC totals={sidebarTotals} side={side} /> : <span />,
      },
      {
        item: {
          label: "🏛 F&A Rates",
          type: "facilities",
          paid: false,
        },
        children: side ? (
          <Facilities totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
      {
        item: {
          label: "💰 Indirect costs",
          type: "indirect",
          paid: false,
        },
        children: side ? (
          <Indirect totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
      {
        item: {
          label: "💵 Fees",
          type: "fees",
          paid: !paid,
        },
        children: side ? <Fees totals={sidebarTotals} side={side} /> : <span />,
      },
      {
        item: {
          label: "🤝 Cost sharing",
          type: "costSharing",
          paid: !paid,
        },
        children: side ? (
          <CostSharing totals={sidebarTotals} side={side} />
        ) : (
          <span />
        ),
      },
    ],
    [side, paid]
  );

  const onOpen = useCallback(() => {
    if (opened) {
      onChangeOpened(false);
      return;
    }
    if (side.id === item.id) {
      onChangeOpened(true);
      return;
    }
    getSide(item.id, () => onChangeOpened(true));
  }, [item, side, getSide, opened]);

  const isActive = useMemo(() => url.grantId === item.id, [item, url]);
  const globalDisabledFinal = side.years.length < 1;
  const isFinalActive = url.category === "final" && url.grantId === side.id;

  return (
    <div className={cx(styles.wrapper, { [styles.last]: last })}>
      <div
        className={cx(styles.card, {
          [styles.active]: isActive,
          [styles.open]: opened,
        })}
      >
        <Icon
          size={20}
          className={cx(styles.chevron, styles.icon)}
          onClick={onOpen}
          icon="chevron-right"
        />
        <div className={styles.titleWrapper}>
          <Link to={`/grants/${item.id}/edit`}>
            <Typo bold size={14} className={styles.title}>
              <span
                title={item.title}
                dangerouslySetInnerHTML={{
                  __html: getHighlightedText(item.title),
                }}
              />
            </Typo>
          </Link>
        </div>
        <Icon
          icon="dots-horizontal"
          size={20}
          onClick={(event: MouseEvent) => onShowMenu(event, item)}
          className={cx(
            styles.menu,
            styles.icon,
            `grant-dots-toggler-${item.id}`
          )}
        />
      </div>
      {opened ? (
        <div className={styles.body}>
          {folders.map((folder: FolderProps, idx: number) => (
            <Folder
              type="category"
              blocked={folder.item.paid}
              icon
              key={`${side.id}-${idx}`}
              grantId={side.id}
              item={folder.item}
            >
              {folder.children}
            </Folder>
          ))}
          <Tip
            top
            when={globalDisabledFinal}
            text="Add general grant information to open the Final form"
          >
            <Anchor
              blocked={false}
              className={styles.finalLink}
              active={isFinalActive}
              disabled={globalDisabledFinal}
              asFolder
              to={`/grants/${side.id}/final`}
            >
              📋 Final form
            </Anchor>
          </Tip>
        </div>
      ) : null}
    </div>
  );
};

export default Card;
