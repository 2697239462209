import { FC, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Modal from "components/modal";
import Spinner from "components/spinner";
import Typo from "components/typo";
import styles from "./protectedRouter.module.css";

type Props = {
  children: any;
};

type ProcessingProps = {
  onRequest: () => void;
};

const ProcessingModal: FC<ProcessingProps> = ({ onRequest }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      onRequest();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Modal visible>
      <div className={styles.processing}>
        <Typo type="h3" className={styles.processingText}>
          Processing your payment information...
        </Typo>
        <Typo type="h6" className={styles.processingText}>
          Hold tight while we process your payment information. This could take
          a few minutes, please don't close this window until it completes.
        </Typo>
        <Spinner className={styles.spinner} />
      </div>
    </Modal>
  );
};

const ProtectedRoute: FC<Props> = ({ children }) => {
  const { token, user, paymentProcessing, softLogin } = useAuth();

  if (!token) {
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom("shutdown");
    }
    return <Redirect from="*" to="/login" />;
  }

  if (user.id) {
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "ueejnag1",
        user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user.firstName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email address
        user_hash: user.intercom,
      });
    }
  }

  return user.id ? (
    <>
      {children}
      {/* {paymentProcessing ? <ProcessingModal onRequest={softLogin} /> : null} */}
    </>
  ) : null;
};

export const AuthRoute: FC<Props> = ({ children }) => {
  const { token } = useAuth();

  useEffect(() => {
    // Add 'auth' class to the body when the component is mounted
    document.body.classList.add("auth");

    // Cleanup function to remove 'auth' class from the body when the component is unmounted
    return () => {
      document.body.classList.remove("auth");
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  if (!token) {
    // @ts-ignore
    if (window.Intercom) {
      setTimeout(() => {
        // @ts-ignore
        window.Intercom("shutdown");
      }, 100);
    }
    return children;
  }
  return <Redirect from="*" to="/grants" />;
};

export default ProtectedRoute;
