import { FC, ReactElement, useEffect, useMemo } from "react";
import cx from "classnames";
import { sortBy } from "lodash";
import Head from "containers/head";
import Typo from "components/typo";
import Help from "components/help";
import Slider from "components/slider";
import useAuth, { AwardedGrant } from "hooks/useAuth";
import { Plan } from "store/auth/types";
import CollapseInfo from "components/collapseInfo";
import Icon from "components/icon";
import { Button } from "components/form";
import { numberToMoney } from "helpers/numbers";
import win from "./images/win.png";
import u1 from "./images/1.png";
import u2 from "./images/2.png";
import u3 from "./images/3.png";
import u4 from "./images/4.png";
import u5 from "./images/5.png";
import u6 from "./images/6.png";
import u7 from "./images/7.png";
import u8 from "./images/8.png";
import u9 from "./images/9.png";
import u10 from "./images/10.png";
import styles from "./billing.module.css";
import dayjs from "dayjs";
import { Subscription } from "components/subscription/Subscription";
import SubscriptionSection from "components/builderio/SubscriptionSection";

type Info = {
  title: string;
  text: string | ReactElement;
};

type Props = {
  plans: Plan[];
  awardedGrant: null | AwardedGrant;
};

const Landing: FC<Props> = ({ plans, awardedGrant }) => {
  const univercities = useMemo(() => {
    const array = [u1, u2, u3, u4, u5, u6, u7, u8, u9, u10];
    return array.map((image, idx: number) => (
      <div className={styles.univercity} key={idx}>
        <img
          src={image}
          alt={`univercity_${idx}`}
          key={idx}
          draggable={false}
        />
      </div>
    ));
  }, []);

  const info = useMemo(() => {
    return [
      {
        title: "Method 1: University Reimbursement",
        text: `
          Normally universities' or research institutions' budgeting or financial departments will reimburse professors and researchers for all educational or required software subscriptions related to their research.
          If you show a receipt of your purchase of Budget Mentor’s software, your institution will probably reimburse you for it.
          All university and research institution policies differ. If you choose this option, it's a good idea to check with your university to confirm their policy.
          This is the safest method to apply for subscription reimbursement, because you will have the chance to be reimbursed regardless of whether you successfully win your research proposal application.
        `,
      },
      {
        title: "Method 2: Professor Startup Funding",
        text: `
          If start up funds were provided by your institution, you can use these funds.
        `,
      },
      {
        title: "Method 3: NSF Reimbursement",
        text: (
          <div>
            <Typo className={styles.infoTitle}>
              NSF Reimbursement Method 1:
            </Typo>
            <Typo className={styles.infoText}>
              The NSF allows you to receive compensation for research expenses
              incurred up to 90 days prior to an awarded proposal. (See
              instructions below)*
            </Typo>
            <Typo className={styles.infoText} type="div">
              <>
                *The PAPPG NSF grant award guidelines note that if you have
                spent money on your grant less than 90 days before the grant was
                awarded, you can request funds for the purchase by adding it to
                the first year of your budget. After this, you must submit a
                request to use these funds by going to the “Notification and
                Request Module” on
                <a href="https://research.gov" target="_blank" rel="noreferrer">
                  {" "}
                  Research.org
                </a>{" "}
                and adding an “Other Request”.
              </>
            </Typo>
            <ol className={styles.infoList}>
              <li>Budget in cost of this software into the proposal</li>
              <li>
                Log into{" "}
                <a href="https://research.gov" target="_blank" rel="noreferrer">
                  {" "}
                  Research.org
                </a>
              </li>
              <li>Click on “Notification and Request” Module</li>
              <li>Click to add “Other Request”</li>
              <li>
                Clearly state that the amount budgeted into your proposal in
                year one that was allocated towards helping you recover the cost
                of Budget Mentor’s budget drafting software.
              </li>
              <li>
                Add in an explanation that no changes are to be made to the
                grant research objectives. Clearly state you are looking for
                explicit understanding and approval that you can reimburse
                yourself for the amount you spent.
              </li>
            </ol>
            <Typo className={styles.infoTitle}>
              NSF Reimbursement Method 2:
            </Typo>
            <Typo className={styles.infoText} type="div">
              <>
                If you <i>did not</i> become a Budget Mentor subscriber within
                90 days of your proposal, this is completely okay. Another valid
                way to get reimbursed by the NSF is to budget in the
                subscription cost into your research budget under the "other"
                section in year one on your National Science Foundation grant
                budget. During your grant budgeting process, our software will
                suggest that you add in your post award Budget Mentor software
                subscription amount. At this stage you should also consider
                reimbursing yourself for the Pre-Award Budget Mentor drafting
                expense. You will be able to see this listed on your grant
                summary.
              </>
            </Typo>
            <Typo className={styles.infoText}>
              The NSF allows you to, within reason, without prior written
              approval of your NSF officer, re-allocate funds as long as you are
              meeting your specified outlined research objectives. This includes
              making minor adjustments to your personal compensation by moving
              funds from one budget category to another, even if this results in
              you getting compensated over a total of 2 months of your
              equivalent yearly salary.
            </Typo>
            <Typo className={styles.infoText}>
              We recommend doing this SPARSELY for ONLY MINOR research related
              expenses that you have paid for out of pocket.
            </Typo>
            <Typo className={styles.infoText}>
              We want to provide a friendly reminder: If your organization
              provides reimbursement for software subscriptions, you won’t have
              to rely on a successful proposal to cover your subscription cost.
            </Typo>
            <Typo className={styles.infoText}>
              We also provide you the option to plan ahead and receive full
              coverage of your post award Budget Mentor subscription costs which
              we do NOT want professors and researchers covering out of their
              own salary.
            </Typo>
          </div>
        ),
      },
      {
        title: "Method 4: Successful Grant Covers Future Budget Mentor Costs",
        text: (
          <div>
            You will have the option to plan ahead and receive full coverage of
            your post award Budget Mentor subscription costs over the full
            duration of your awarded grant timeline. If you successfully win
            your grant, you can switch your Budget Mentor subscription billing
            over to your grant's funds. At this point in time you will no longer
            be billed out of your own personal funds for subscription renewals
            as long as your grant is active and you have research funds
            available.
            <div>Example flow:</div>
            <ol>
              <li>
                You create a grant and budget in coverage for the post-award
                subscription price for the full duration of your grant.
              </li>
              <li>You win your grant!</li>
              <li>
                You log onto Budget Mentor and switch your billing over to your
                newly funded grant.
              </li>
              <li>
                You next yearly Budget Mentor subscription will be able to be
                billed to your new grant!
              </li>
            </ol>
            Note: We do NOT want professors and researchers covering the post
            award subscription price out of their own salary. We HIGHLY
            recommend making room for this expense so you don't have to cover
            the full price of future Budget Mentor subscription renewals. In the
            future, we plan to develop tools to help researchers more easily
            manage their grants. Including the full post-award price of Budget
            Mentor’s subscription in your grant will allow you to have full
            unrestricted access to any future post award grant tools that we
            develop.
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div className={styles.wrapper}>
      <Head title="Subscription plan" />
      <div className={styles.header}>
        <Typo type="h4" className={styles.tabTitle} semi>
          <>Subscription plan</>
        </Typo>
        <Typo className={styles.subtitle} size={16}>
          Manage your billing and payment details
        </Typo>
      </div>

      <SubscriptionSection />

      <div className={styles.plans}>
        <Subscription />
      </div>

      <div className={styles.list}>
        <Typo semi type="h4" className={styles.sectionTitle}>
          Pricing Explained
        </Typo>
        <div className={styles.listItem}>
          <span className={styles.dot} />
          <Typo size={16}>
            <>
              <strong>Your Success = Our Commitment</strong>: Pay the discounted
              rate until you secure an NSF grant with our software.
            </>
          </Typo>
        </div>

        <div className={styles.listItem}>
          <span className={styles.dot} />
          <Typo size={16}>
            <>
              <strong>NSF Covers Cost</strong>: Once you win a grant, the
              software fee is sourced directly from the grant—not your pocket.
            </>
          </Typo>
        </div>

        <div className={styles.listItem}>
          <span className={styles.dot} />
          <Typo size={16}>
            <>
              <strong>Active Grant Pricing</strong>: Standard subscription fee
              applies during the duration of an active grant.
            </>
          </Typo>
        </div>

        <div className={styles.listItem}>
          <span className={styles.dot} />
          <Typo size={16}>
            <>
              <strong>No Active Grant?</strong>: Enjoy the discounted rate again
              during grant lulls.
            </>
          </Typo>
        </div>

        <div className={styles.listItem}>
          <Typo size={16}>
            <>
              <strong>🔍 Why Choose Our Software?</strong>
            </>
          </Typo>
        </div>

        <div className={styles.listItem}>
          <span className={styles.dot} />
          <Typo size={16}>Used by successful NSF awardees.</Typo>
        </div>

        <div className={styles.listItem}>
          <span className={styles.dot} />
          <Typo size={16}>
            Designed with the intricacies of the NSF grant process in mind.
          </Typo>
        </div>

        <div className={styles.listItem}>
          <Typo size={16}>
            🤝 Our Mission: Partnering with you to foster knowledge and
            innovation.
          </Typo>
        </div>
      </div>

      <Typo semi type="h4" className={styles.sectionTitle}>
        Budget Mentor is popular in such educational institutions
      </Typo>
      <Slider per={5} className={styles.slider}>
        {univercities}
      </Slider>

      <div className={styles.header}>
        <Typo type="h4" className={styles.tabTitle} semi>
          <>
            Reimbursement methods
            <Help contentClassName={styles.help}>
              This section outlines different strategies for obtaining
              reimbursement for your Budget Mentor subscription. We hope to
              offer insight into financial options available across various
              institutions and grant funding scenarios.
            </Help>
          </>
        </Typo>
        <Typo className={styles.subtitle} size={16}>
          User needs the ability to access the information about how they can
          get reimbursed for the software in the user settings.
        </Typo>
      </div>
      {info.map((item: Info, idx: number) => (
        <CollapseInfo arrow key={idx} className={styles.info} {...item} />
      ))}
    </div>
  );
};

const Billing: FC = () => {
  const { user, paid, managePlan, getAwardedGrant, awardedGrant, plans } =
    useAuth();

  useEffect(() => {
    getAwardedGrant();
  }, []);

  return <Landing plans={plans} awardedGrant={awardedGrant} />;
};

export default Billing;
