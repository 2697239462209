import { useState, useEffect } from "react";
import styles from "./Subscription.module.css";
import Typo from "components/typo";
import { useMutation } from "react-query";
import client from "helpers/apiClient";
import useAuth from "hooks/useAuth";
import toast from "components/toast";

const ProductDisplay = () => (
  <section className={styles.product}>
    <div>
      <Typo type="h4" semi>
        Grant
      </Typo>
      <Typo className={styles.subtitle} size={16}>
        80$ / month
      </Typo>
    </div>
    <SubscribeButton />
  </section>
);

function SubscribeButton() {
  const manageInformation = useMutation({
    mutationFn: async () => {
      const response = await client.post("subscriptions/checkout");
      return response.data;
    },
    onSuccess(data: any) {
      window.location.replace(data.url);
    },
  });

  return (
    <button
      className={styles.button}
      onClick={() => manageInformation.mutateAsync()}
    >
      Subscribe
    </button>
  );
}

export function ManageBillingInformationButton() {
  const manageInformation = useMutation({
    mutationFn: async () => {
      const response = await client.post("subscriptions/customer-portal");
      return response.data;
    },
    onSuccess(data: any) {
      window.location.replace(data.url);
    },
  });

  return (
    <button
      className={styles.button}
      onClick={() => manageInformation.mutateAsync()}
    >
      Manage your billing information
    </button>
  );
}

const SuccessDisplay = () => {
  return (
    <section>
      <Typo type="h4" semi>
        Subscription to plan successful!
      </Typo>
      <ManageBillingInformationButton />
    </section>
  );
};

export function Subscription() {
  const { user } = useAuth();
  const [freshlySubscribed, setFreshlySubscribed] = useState(false);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      toast.info({
        title: "Subscription started",
        message: "You successfully subscribed to BudgetMentor",
      });
      setFreshlySubscribed(true);
    }

    if (query.get("canceled")) {
      toast.info({
        title: "Error",
        message: "You have not completed the payment",
      });
    }

    window.history.replaceState(
      {},
      "",
      window.location.origin + window.location.pathname
    );
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {user.billing?.subscription.isActive || freshlySubscribed ? (
        <SuccessDisplay />
      ) : (
        <ProductDisplay />
      )}
    </div>
  );
}
