import { FC, useCallback, ReactElement, useMemo } from "react";
import ReactSelect from "react-select";
import cx from "classnames";
import Field from "components/form/field";
import styles from "components/form/form.module.css";
import { useFormContext, Controller } from "react-hook-form";
import { findInputError, RHErrors } from "helpers/errors";

type Option = {
  label: string | ReactElement | number;
  value: any;
  disabled?: boolean;
};

type Props = {
  name: string;
  options: Option[];
  placeholder?: string;
  value?: string | number | null | boolean;
  onChange?: (value: any) => void;
  label?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  hint?: string;
  scroll?: boolean;
  inputClassName?: string;
  help?: ReactElement | string;
  pure?: boolean;
  bool?: boolean;
  className?: string;
  defaultValueToScroll?: string;
  overflow?: boolean;
  helpInLabel?: boolean;
};

const Select: FC<Props> = (props: Props) => {
  const {
    name,
    value,
    onChange,
    label,
    className,
    errors = [],
    required,
    disabled,
    inputClassName,
    hint,
    pure,
    options,
    placeholder = "Select an option",
    help,
    helpInLabel,
    bool,
  } = props;

  const { formState, setValue, clearErrors, control } = useFormContext();

  const handleChange = useCallback(
    ({ value }: any) => {
      if (onChange) onChange(value);
      setValue(`${name}-select`, value);
      if (required && (value !== undefined || value !== null))
        clearErrors(`${name}-select`);
    },
    [required, setValue, clearErrors, name, onChange]
  );

  const inputErrors = findInputError(
    formState.errors as RHErrors,
    `${name}-select`
  );

  const errorsList = inputErrors.length > 0 ? inputErrors : errors;
  const invalid = errorsList.length > 0;
  const rules = {
    validate: (value: any) => {
      if (value == null) {
        return "Field is required";
      }
      return true;
    },
    value: bool ? options.map((item: Option) => item.value) : value,
  };

  const currentOption = useMemo(
    () => options.find((item: Option) => item.value === value),
    [options, value]
  );

  return (
    <Field
      help={help}
      helpInLabel={helpInLabel}
      className={className}
      required={required}
      label={label}
      hint={hint}
      errors={errorsList}
      name={name}
    >
      <Controller
        name={`${name}-select`}
        control={control}
        defaultValue={value}
        rules={rules}
        render={({ field }) => (
          <ReactSelect
            value={currentOption}
            className={cx(styles.selectWrapper, styles.newSelectWrapper, {
              [styles.pureSelect]: pure,
              [styles.invalidSelect]: invalid,
            })}
            classNames={{
              control: (state) =>
                cx(styles.newSelect, {
                  [styles.newSelectFocused]:
                    state.menuIsOpen || state.isFocused,
                  [styles.newSelectError]: invalid,
                  [styles.newSelectMenuPure]: pure,
                }),
              menuList: () => cx(styles.newSelectMenu, "bm-select"),
              valueContainer: () =>
                cx(styles.newSelectContainer, inputClassName),
              indicatorsContainer: () => styles.newSelectChevronWrapper,
              indicatorSeparator: () => styles.hidden,
              menu: () => styles.newSelectMenuWrapper,
              option: (state) =>
                cx(styles.newSelectMenuItem, {
                  [styles.newSelectMenuItemActive]: state.isSelected,
                  [styles.newSelectMenuItemChosen]: state.isFocused,
                }),
              placeholder: () => styles.newSelectPlaceholder,
            }}
            name={field.name}
            isSearchable={false}
            maxMenuHeight={320}
            openMenuOnFocus
            onChange={handleChange} //@ts-ignore
            disabled={disabled}
            placeholder={placeholder}
            //@ts-ignore
            options={options}
          />
        )}
      />
    </Field>
  );
};

export default Select;
