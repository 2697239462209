import { useEffect, useState } from "react";
// import { BuilderComponent, builder } from "@builder.io/react";

// Replace with your Public API Key.
// builder.init(process.env.REACT_APP_BUILDER_IO!);

const BLOCK_ID = "no-subscription-dialog";

export default function NoSubscriptionDialog() {
  const [content, setContent] = useState(undefined);

  // useEffect(() => {
  //   builder
  //     .get(BLOCK_ID, {
  //       userAttributes: {
  //         // To allow targeting different announcements at different pages (URLs)
  //         urlPath: window.location.pathname,
  //       },
  //     })
  //     .toPromise()
  //     .then((val) => setContent(val));
  // }, []);

  return <>{/* <BuilderComponent model={BLOCK_ID} content={content} /> */}</>;
}
