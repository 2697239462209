import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cx from "classnames";
import { ceil } from "lodash";
import { Button, Form, Input, Slider } from "components/form";
import styles from "./compensation.module.css";
import { Errors } from "helpers/errors";
import { useParams } from "react-router-dom";
import useGrants from "hooks/useGrants";
import usePersonnel from "hooks/usePersonnel";
import { PersonalNSFCompensation } from "store/grants/types";
import { CalculatePostDocNSFResponse } from "hooks/requestTypes";
import { getPostDocPersonFromGrant } from "helpers/getFromGrant";
import { percentToFixed } from "helpers/numbers";
import GrantFormWrapper from "pages/grant/common/wrapper";
import { FieldErrors } from "react-hook-form";

export type PostDocPercentCompensationData = {
  percent: number;
  percentForAccountOwner: number | string | undefined;
  requestedSalarySupportAfterInflation?: number;
  requestedSalarySupportBeforeInflation?: number;
  requestedSalarySupportAfterInflationForAccountOwner?: number;
};

type Props = {
  onBlur: () => void;
  onSubmit: () => void;
  errors: Errors;
  loading: boolean;
  owner: boolean;
  year: number;
  onChange: (field: Record<string, any>) => void;
  compensation: PostDocPercentCompensationData;
  trackError: (errors: FieldErrors) => void;
  trackExit: () => void;
  trackFormStarted: () => void;
};

const CompensationPercentForm: FC<Props> = ({
  errors,
  owner,
  loading,
  compensation,
  year,
  onSubmit,
  onBlur,
  onChange,
  trackError,
  trackExit,
  trackFormStarted,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      prompt
      onSubmit={handleSubmit}
      trackError={trackError}
      trackExit={trackExit}
      trackFormStarted={trackFormStarted}
    >
      <div className={styles.field}>
        <Slider
          name="percent"
          postfix="%"
          errors={errors[`${year - 1}.percent`] || errors.percent}
          value={compensation.percent}
          onBlur={onBlur}
          onChange={(percent: number) => onChange({ percent })}
          label="Estimated Percent of Yearly Salary Requested for support"
          required
        />
      </div>
      <div className={cx(styles.field, styles.twoInputsField)}>
        <Input
          name="requestedSalarySupportBeforeInflation"
          money
          value={
            compensation.requestedSalarySupportBeforeInflation
              ? ceil(compensation.requestedSalarySupportBeforeInflation, 2)
              : undefined
          }
          readOnly
          disabled
          className={styles.input}
          label={`Requested Salary Support in Year ${year} Before Inflation`}
          placeholder="Requested Salary Support Before Inflation"
        />
        <Input
          name="requestedSalarySupportAfterInflation"
          money
          value={
            compensation.requestedSalarySupportAfterInflation
              ? ceil(compensation.requestedSalarySupportAfterInflation, 2)
              : undefined
          }
          readOnly
          disabled
          className={styles.input}
          label={`Requested Salary Support in Year ${year} after Inflation`}
          help={
            year === 1
              ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
              : undefined
          }
          placeholder="Requested Salary Support After Inflation"
        />
      </div>
      <div className={styles.field}>
        <Input
          name="percentForAccountOwner"
          type="number"
          value={compensation.percentForAccountOwner}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange({ percentForAccountOwner: event.target.value })
          }
          className={styles.fullInput}
          onBlur={onBlur}
          disabled={!owner}
          help={`The field is available if the "Account owner is a postdoc" switch is enabled. Please indicate the percentage of compensation that should be allocated to the account owner. Based on this and the amount of compensation after inflation, we will be able to calculate the account owner's salary budget and display it on the dashboard.`}
          label="What percentage of compensation should be allocated to account owner?"
          required={owner}
          placeholder="Enter the percentage of compensation that should be allocated to the account owner"
        />
      </div>
      <div className={cx(styles.field, styles.last)}>
        <Input
          name="requestedSalarySupportAfterInflationForAccountOwner"
          value={
            compensation.requestedSalarySupportAfterInflationForAccountOwner !==
            undefined
              ? ceil(
                  compensation.requestedSalarySupportAfterInflationForAccountOwner,
                  2
                )
              : undefined
          }
          readOnly
          disabled
          className={styles.fullInput}
          money
          label={`Requested Salary Support in Year ${year} after Inflation for Account owner`}
          help={
            year === 1
              ? `Inflation is not applied in Year ${year} for any costs, but will be applied in subsequent years.`
              : undefined
          }
          placeholder="Requested Salary Support for Account owner"
        />
      </div>
      <div className={styles.formFooter}>
        <Button htmlType="submit" name="submit" size="xl" loading={loading}>
          Save
        </Button>
      </div>
    </Form>
  );
};

const PostDocCompensation: FC = () => {
  // const {formStarted, formSaveAttempted, formSaveFailed, formExited, formSaved} = useMixPanel();
  const params: Record<string, string> = useParams();
  const { grant } = useGrants();

  const {
    loading,
    updatePostDocNsf,
    calculatePostDocNSF,
    errors,
    trackError,
    trackExit,
    trackFormStarted,
  } = usePersonnel({
    form_page_type: "Date",
  });
  const { person, index } = getPostDocPersonFromGrant(grant, params.personId);
  const compensation: PersonalNSFCompensation | undefined = useMemo(() => {
    return person?.nsfCompensation[Number(params.year)];
  }, [person, params]);

  const [data, onChangeData] = useState<PostDocPercentCompensationData>(() => {
    return {
      percent: compensation?.percent ? percentToFixed(compensation.percent) : 0,
      percentForAccountOwner: compensation?.percentForAccountOwner
        ? percentToFixed(compensation.percentForAccountOwner)
        : "",
      requestedSalarySupportAfterInflation:
        compensation?.requestedSalarySupportAfterInflation ?? "",
      requestedSalarySupportBeforeInflation:
        compensation?.requestedSalarySupportBeforeInflation ?? "",
      requestedSalarySupportAfterInflationForAccountOwner:
        compensation?.requestedSalarySupportAfterInflationForAccountOwner ?? "",
    };
  });

  const handleChangeData = useCallback(
    (field: Record<string, any>) => {
      onChangeData({
        ...data,
        ...field,
      });
    },
    [data]
  );

  const onBlur = useCallback(() => {
    if (compensation) {
      calculatePostDocNSF(
        grant.id,
        params.personId,
        {
          percent: Number(data.percent) / 100,
          serialNumber: compensation?.serialNumber ?? 1,
          year: compensation?.year, //@ts-ignore
          ...(person.isAccountOwner
            ? {
                percentForAccountOwner:
                  Number(data.percentForAccountOwner) / 100,
              }
            : {}),
        },
        (response: CalculatePostDocNSFResponse) => {
          onChangeData({
            ...data,
            requestedSalarySupportAfterInflation:
              response.requestedSalarySupportAfterInflation,
            requestedSalarySupportBeforeInflation:
              response.requestedSalarySupportBeforeInflation,
            requestedSalarySupportAfterInflationForAccountOwner:
              response.requestedSalarySupportAfterInflationForAccountOwner,
          });
        }
      );
    }
  }, [person, data, compensation, params, calculatePostDocNSF, grant]);

  useEffect(() => {
    onBlur();
  }, [params.year, params.personId]);

  const onSubmit = useCallback(() => {
    if (compensation) {
      updatePostDocNsf(
        grant.id,
        params.personId,
        {
          ...data,
          year: compensation.year,
          serialNumber: compensation.serialNumber,
          percent: Number(data.percent) / 100,
          percentForAccountOwner: Number(data.percentForAccountOwner) / 100,
          requestedSalarySupportAfterInflation: Number(
            data.requestedSalarySupportAfterInflation
          ),
          requestedSalarySupportBeforeInflation: Number(
            data.requestedSalarySupportBeforeInflation
          ),
          requestedSalarySupportAfterInflationForAccountOwner: Number(
            data.requestedSalarySupportAfterInflationForAccountOwner
          ),
        },
        Number(params.year)
      );
    }
  }, [data, params, compensation, updatePostDocNsf, grant]);

  return (
    <GrantFormWrapper
      title={"Postdoctoral Research Associate"}
      subtitle={`Position ${index}. Year ${Number(params.year) + 1}`}
    >
      <CompensationPercentForm
        loading={loading}
        owner={Boolean(person?.isAccountOwner)}
        errors={errors}
        year={Number(params.year) + 1}
        compensation={data}
        onChange={handleChangeData}
        onBlur={onBlur}
        onSubmit={onSubmit}
        trackError={trackError}
        trackExit={trackExit}
        trackFormStarted={trackFormStarted}
      />
    </GrantFormWrapper>
  );
};

export default PostDocCompensation;
